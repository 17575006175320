<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'RouterMonte',
	}

</script>